import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'content-type': 'application/json;text/html; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
    'Accept': 'application/json;text/html; '
  })
};
const apiUrl = "http://86.109.120.163/InformeFotos/obtenerImagenes.php";
const cameraURL = "http://86.109.120.163/InformeFotos/obtenerCamaras.php";
const resultadoURL = "http://86.109.120.163/InformeFotos/resultado.php";
const informesURL = "http://86.109.120.163/InformeFotos/obtenerInformes.php";
const informeURL = "http://86.109.120.163/InformeFotos/detalleInforme.php";

@Injectable({
  providedIn: 'root'
})
export class PhpRestService {

  constructor(public http: HttpClient) {
    
  }
  getImages(camaraBusqueda, fechaBusqueda, segundosPrecision, hotel): Observable<any[]> {
    let body = new FormData();
    body.append("camara", camaraBusqueda); // Inserim les dades al post
    body.append("diaInicio", fechaBusqueda.split("T")[0]);
    body.append("horaInicio", fechaBusqueda.split("T")[1].split(".")[0]);
    body.append("precision", segundosPrecision);
    body.append("hotel", hotel);
    return this.http.post<any[]>(apiUrl, body).pipe(tap((result: any[]) => {
      console.log(result);
    }),
      catchError(this.handleError<any>('addProduct'))
    );
  }

  getCameras(): Observable<any[]> {
    return this.http.get<any[]>(cameraURL, {}).pipe(tap((result: any[]) => {
      console.log(result);
    }),
      catchError(this.handleError<any>('addProduct'))
    );
  }

  postInforme(fotos, nombre, descripcion, hotel): Observable<any[]> {
    let body = new FormData();
    body.append("imagenes", JSON.stringify(fotos));
    body.append("carpetaDestino", hotel);
    body.append("nombre", nombre);
    body.append("descripcion", descripcion);
    return this.http.post<any[]>(resultadoURL, body).pipe(tap((result: any[]) => {
      console.log(result);
    }),
      catchError(this.handleError<any>('addProduct'))
    );
  }

  getInformes(): Observable<any[]> {
    return this.http.get<any[]>(informesURL, {}).pipe(tap((result: any[]) => {
      console.log(result);
    }),
      catchError(this.handleError<any>('addProduct'))
    );
  }

  getInforme(nombreInforme): Observable<any[]> {
    let body = new FormData();
    body.append("nombreInforme", nombreInforme);
    return this.http.post<any[]>(informeURL, body).pipe(tap((result: any[]) => {
      console.log(result);
    }),
      catchError(this.handleError<any>('addProduct'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Aquí carregam l'error per consola
      return of(result as T);
    };
  }
}
